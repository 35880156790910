import { initializeApp } from "firebase/app";
import {
  getFirestore,
  connectFirestoreEmulator,
} from "firebase/firestore/lite";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

let project = process.env.REACT_APP_PROJECT;

const configs = {
  "beatice-testing": {
    apiKey: "AIzaSyA4yUF-UE97omt3iHs50BsHRjvIZB1cU1Y",
    authDomain: "beatice-testing.firebaseapp.com",
    projectId: "beatice-testing",
    storageBucket: "beatice-testing.appspot.com",
    messagingSenderId: "383200899844",
    appId: "1:383200899844:web:94c8f5f659aaabef4cd651",
  },
  "beatice-prod": {
    apiKey: "AIzaSyDLe0I6DL3uEdfsjZXtkE1nrRk8rJ7JTvs",
    authDomain: "beatice-prod.firebaseapp.com",
    projectId: "beatice-prod",
    storageBucket: "beatice-prod.appspot.com",
    messagingSenderId: "912833932782",
    appId: "1:912833932782:web:135b648d7c4fd5d2b932ff",
  },
};

const app = initializeApp(configs[project]);
const db = getFirestore(app);
const auth = getAuth();
const functions = getFunctions(app);

if (process.env.NODE_ENV == "development" || process.env.NODE_ENV == "test") {
  connectFirestoreEmulator(db, "127.0.0.1", 8080);
  connectAuthEmulator(auth, "http://127.0.0.1:9099");
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}

export { app, db, auth, functions };
