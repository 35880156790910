import logo from "./logo.svg";
import "./App.css";
import { app, auth, db, functions } from "./firebase/firebase.js";

console.log("project id: ", app.options.projectId);
console.log(
  "connected to emulator: ",
  "emulatorConfig" in auth &&
    db._settings.host == "127.0.0.1:8080" &&
    "emulatorOrigin" in functions,
);

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload. <br />
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
